import React from "react"
import { navigate } from "gatsby"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import IdeaGenerator from "../../../../../components/idea-generator"

const Page = () => {
  const { submitForm, getTask } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })
  const taskProps = {
    courseId: "jahreszeiten",
    chapterId: "02-eigenes-gedicht",
    taskId: "ideen-sammeln",
  }
  const task = getTask(taskProps)

  return (
    <LayoutCourse
      as="form"
      onSubmit={(event) => {
        event.preventDefault()
        submitForm({
          ...taskProps,
          event,
        })
        navigate("/kurse/jahreszeiten/02-eigenes-gedicht/reim-und-klang")
      }}
      navigation={navigation}
      footer={<CourseFooterNext type="submit" value="submit" />}
    >
      <Seo title="Ideen sammeln" />
      <Stack>
        <Stack space={3}>
          <Heading as="h1" level={2}>
            Ideen sammeln
          </Heading>
        </Stack>
        <Paragraph>
          Überlege dir, was in deinem vorkommen könnte: Wetter- und
          Naturphänomene, die du mit der Jahreszeit verbindest? Ein Gefühl oder
          eine Stimmung, die beim Gedanken an die Jahreszeit in dir aufkommen?
          Eine Erinnerung oder ein Ereignis, das dir dabei einfällt? Lege eine
          Liste mit Ideen und Stichworten an.
        </Paragraph>
        <IdeaGenerator suggestions={task.solution} />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
