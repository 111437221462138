import PropTypes from "prop-types"
import React, { useState, useRef } from "react"
import produce from "immer"
import Textarea from "./textarea"
import Stack from "./stack"
import TextLink from "./textlink"

const IdeaGenerator = ({ suggestions }) => {
  const [isDirty, setIsDirty] = useState(false)
  const [lastSuggestionIndex, setLastSuggestionIndex] = useState(null)
  const textareaRef = useRef(null)

  const onClickExample = (event) => {
    event.preventDefault()
    if (suggestions.length <= 0) {
      return
    }

    // remove latest suggestion from getting picked again
    const randomizableSuggestions = produce(suggestions, (draft) => {
      if (lastSuggestionIndex !== null) {
        draft.splice(lastSuggestionIndex, 1)
      }
    })

    const randomIndex = Math.floor(
      Math.random() * randomizableSuggestions.length
    )
    const randomSuggestion = randomizableSuggestions[randomIndex]
    const originalIndex = suggestions.indexOf(randomSuggestion)
    setLastSuggestionIndex(originalIndex)

    textareaRef.current.value = randomSuggestion
  }
  return (
    <Stack space={4}>
      <Textarea
        ref={textareaRef}
        id="idea"
        name="idea"
        onChange={(event) => {
          setIsDirty(!!event.target.value)
        }}
        rows={8}
        placeholder="Meine Ideen"
      />
      {!isDirty && (
        <TextLink onClick={onClickExample}>Ich möchte einen Vorschlag</TextLink>
      )}
    </Stack>
  )
}

IdeaGenerator.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.string),
}

IdeaGenerator.defaultProps = {
  suggestions: [],
}

export default IdeaGenerator
